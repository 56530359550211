.editor-styles-wrapper .wp-block-acf-spotlight-listing .spotlight-listing .spotlight-listing__spotlights {
  display: -webkit-flex;
  display: flex;
}

.spotlight-listing {
  background-color: #162224;
  margin-top: -1px;
  padding: 1em 0 5.1875em;
  overflow: hidden;
}

@media (width <= 768px) {
  .spotlight-listing {
    padding: 1em 0 2.6875em;
  }
}

.spotlight-listing__spotlights:not(.carousel) {
  display: -webkit-flex;
  display: flex;
}

@media (width <= 768px) {
  .spotlight-listing__spotlights.carousel {
    padding-top: 7em;
  }
}

.spotlight-listing__spotlights.carousel .flickity-viewport {
  overflow: visible;
}

@media (width <= 768px) {
  .spotlight-listing__spotlights.carousel .flickity-viewport {
    overflow: hidden;
  }
}

.spotlight-listing__spotlights.carousel .flickity-button {
  background-color: #0000;
  width: 60px;
  height: 60px;
  top: 200px;
  -webkit-transform: translateY(0%)rotate(180deg);
  transform: translateY(0%)rotate(180deg);
}

@media (width <= 768px) {
  .spotlight-listing__spotlights.carousel .flickity-button {
    top: 0;
  }
}

.spotlight-listing__spotlights.carousel .flickity-button:after {
  content: "";
  background-image: url("../../../assets/images/carousel-arrow.svg");
  width: 60px;
  height: 60px;
  position: absolute;
}

.spotlight-listing__spotlights.carousel .flickity-button svg {
  display: none;
}

.spotlight-listing__spotlights.carousel .flickity-button.previous {
  left: auto;
  right: 0;
}

@media (width <= 768px) {
  .spotlight-listing__spotlights.carousel .flickity-button.previous {
    right: 80px;
  }
}

.spotlight-listing__spotlights.carousel .flickity-button.previous:after {
  -webkit-transform: translate(-50%, -50%)rotate(180deg);
  transform: translate(-50%, -50%)rotate(180deg);
}

@media (width <= 768px) {
  .spotlight-listing__spotlights.carousel .flickity-button.previous:after {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.spotlight-listing__spotlights.carousel .flickity-button.next {
  left: auto;
  right: 80px;
}

@media (width <= 768px) {
  .spotlight-listing__spotlights.carousel .flickity-button.next {
    right: 0;
  }
}

.spotlight-listing__spotlights.carousel .flickity-button.next:after {
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (width <= 768px) {
  .spotlight-listing__spotlights.carousel .flickity-button.next:after {
    -webkit-transform: translate(-50%, -50%)rotate(180deg);
    transform: translate(-50%, -50%)rotate(180deg);
  }
}

.spotlight-listing__card {
  width: 18.75em;
  margin: 0 1.875em;
}

@media (width <= 768px) {
  .spotlight-listing__card {
    width: 100%;
  }
}

.spotlight-listing__card-image-name {
  border-radius: 10px;
  height: 25em;
  padding: 1.125em 1.75em;
  transition: -webkit-transform .3s, transform .3s;
  display: -webkit-flex;
  display: flex;
  position: relative;
  overflow: hidden;
}

.spotlight-listing__card-image-name:hover, .spotlight-listing__card-image-name:focus {
  text-decoration: none;
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

@media (width <= 768px) {
  .spotlight-listing__card-image-name:hover, .spotlight-listing__card-image-name:focus {
    -webkit-transform: none;
    transform: none;
  }
}

.spotlight-listing__card-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.spotlight-listing__card-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.spotlight-listing__card-image:after {
  content: "";
  background-image: url("../../../assets/images/gradient--orange.png");
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.spotlight-listing__card-name-title {
  align-self: flex-end;
  position: relative;
}

.spotlight-listing__card-name-title p {
  color: #162224;
  margin: 0;
  font-family: Eina Semi Bold, sans-serif;
  font-size: 1.25em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4em;
}

.spotlight-listing__card-name-title p:first-child {
  font-size: 1.875em;
  line-height: .933333em;
}

.spotlight-listing__card-content {
  margin-top: 1.875em;
}

.spotlight-listing__card-tags {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
  display: -webkit-flex;
  display: flex;
}

.spotlight-listing__card-tag {
  color: #162224;
  letter-spacing: 1.4px;
  text-transform: lowercase;
  background-color: #b7bbff;
  border-radius: 20px;
  padding: .714286em 1.42857em;
  font-family: Eina Semi Bold, sans-serif;
  font-size: .875em;
  font-style: normal;
  font-weight: 600;
}

.spotlight-listing__card-summary {
  color: #f5f5f5;
  letter-spacing: -.54px;
  margin: 1.16667em 0;
  font-size: 1.125em;
  line-height: 1.33333em;
}

.spotlight-listing__card-summary p {
  font-family: Pitch Medium, sans-serif;
  font-style: normal;
  font-weight: 600;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.spotlight-listing__card-link, .spotlight-listing__card-link:visited {
  cursor: pointer;
  color: #ffda48;
  letter-spacing: -.16px;
  text-transform: none;
  background-color: #0000;
  border: none;
  border-bottom: 2px solid #ffda48;
  border-radius: 0;
  align-items: center;
  gap: 10px;
  padding: 0 0 8px;
  font-family: Eina Bold, sans-serif;
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  line-height: .875em;
  transition: gap .3s;
  display: -webkit-inline-flex;
  display: inline-flex;
  position: relative;
}

.spotlight-listing__card-link:hover, .spotlight-listing__card-link:active {
  gap: 20px;
  text-decoration: none;
  color: #ffda48 !important;
  background-color: #0000 !important;
}

.carousel-container {
  position: relative;
  -webkit-transform: translateX(-212px);
  transform: translateX(-212px);
}

@media (width <= 768px) {
  .carousel-container {
    -webkit-transform: none;
    transform: none;
  }
}

.carousel-hidden-area {
  z-index: 2;
  background-color: #162224;
  width: 30%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

@media (width <= 768px) {
  .carousel-hidden-area {
    display: none;
  }
}
/*# sourceMappingURL=spotlight-listing.css.map */
