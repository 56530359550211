/*
 * Easy conversion to em from pixels
 */
@function _em($target, $context: $base-font-size) {
	@if $target==0 {
		@return 0
	;}

	@return calc($target / $context)+0em;
}


/*
* Breakpoints
*/
@mixin breakpoint($class) {
	@if $class==xxs {
		@media (max-width: $breakpoint-xxs) {
			@content;
		}
	}

	@else if $class==xs {
		@media (max-width: $breakpoint-xs) {
			@content;
		}
	}

	@else if $class==sm {
		@media (max-width: $breakpoint-sm) {
			@content;
		}
	}

	@else if $class==md {
		@media (max-width: $breakpoint-md) {
			@content;
		}
	}

	@else if $class==lg {
		@media (max-width: $breakpoint-lg) {
			@content;
		}
	}

	// New Breackpoints
	@else if $class==gutenberg {
		@media (max-width: 781px) {
			@content;
		}
	}

	@else {
		@warn "Breakpoint mixin supports: xxs, xs, sm, md, lg.";
	}
}


/*
* Using variation specific font names from the start to avoid IE8 issues
* http://help.typekit.com/customer/portal/articles/6855-Using-multiple-weights-and-styles
*/
@mixin font($weight, $style: normal) {
	@if $weight==light {

		font-weight: 300;
		@if $style==italic {
			font-family: "Eina Light Italic", sans-serif;
			font-style: italic;
		}

		@else {
			font-family: "Eina Light", sans-serif;
			font-style: normal;
		}
	}

	@else if $weight==regular {

		font-weight: 400;
		@if $style==italic {
			font-family: "Eina Regular Italic", sans-serif;
			font-style: italic;
		}

		@else {
			font-family: "Eina Regular", sans-serif;
			font-style: normal;
		}
	}

	@else if $weight==semibold {

		font-weight: 600;
		@if $style==italic {
			font-family: "Eina Semi Bold Italic", sans-serif;
			font-style: italic;
		}

		@else {
			font-family: "Eina Semi Bold", sans-serif;
			font-style: normal;
		}
	}

	@else if $weight==bold {

		font-weight: 700;
		@if $style==italic {
			font-family: "Eina Bold Italic", sans-serif;
			font-style: italic;
		}

		@else {
			font-family: "Eina Bold", sans-serif;
			font-style: normal;
		}
	}
}

@mixin font-alt($style: normal) {

	font-weight: 600;
	@if $style==italic {
		font-family: "Pitch Medium Italic", sans-serif;
		font-style: italic;
	}

	@else {
		font-family: "Pitch Medium", sans-serif;
		font-style: normal;
	}
}
