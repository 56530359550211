/*
 * Button mixins
 */

@mixin fill-button($color: 'sun'){
	
	&,
	&:visited {
		@include font(bold);
		display: flex;
		align-items: center;
		border: 2px solid $color-black;
		border-radius: 40px;
		background-color: $color-sun;
		padding: 20px;
		cursor: pointer;
		color: $color-black;
		letter-spacing: 1.4px;
		text-transform: uppercase;
		font-size: _em(14, 16);
		line-height: _em(14, 14);
		transition: background-color 0.3s, color 0.3s;
		gap: 10px;

		@if $color=='periwinkle' {
			background-color: $color-periwinkle;
		}
		@if $color=='orange' {
			background-color: $color-orange;
		}
		@if $color=='peach' {
			background-color: $color-peach;
		}
	}

	&:hover,
	&:active {
		background-color: $color-moon !important;
		color: $color-black !important;
		text-decoration: none;
	}
}

@mixin outline-button($color: 'moon'){
	
	&,
	&:visited {
		@include font(bold);
		display: inline-flex;
		align-items: center;
		border: 2px solid $color-moon;
		border-radius: 40px;
		background-color: transparent;
		padding: 15px 20px;
		cursor: pointer;
		color: $color-moon;
		letter-spacing: 1.4px;
		text-transform: uppercase;
		font-size: _em(14, 16);
		line-height: _em(14, 14);
		transition: background-color 0.3s, color 0.3s;
		
		svg {
			path {
				fill: $color-moon !important;
				transition: fill 0.3s;
			}
		}

		@if $color=='sun' {
			border: 2px solid $color-sun;
		}
		@if $color=='periwinkle' {
			border: 2px solid $color-periwinkle;
		}
		@if $color=='orange' {
			border: 2px solid $color-orange;
		}
		@if $color=='blue' {
			border: 2px solid $color-blue;
		}
		@if $color=='peach' {
			border: 2px solid $color-peach;
		}
	}

	&:hover,
	&:active {
		background-color: $color-moon !important;
		color: $color-black !important;
		text-decoration: none;

		svg {
			path {
				fill: $color-black !important;
			}
		}
	}
}

@mixin underline-button($color: 'black'){
	
	&,
	&:visited {
		@include font(bold);
		display: inline-flex;
		position: relative;
		align-items: center;
		border: none;
		border-bottom: 2px solid $color-black;
		border-radius: 0;
		background-color: transparent;
		padding: 0 0 8px 0;
		cursor: pointer;
		color: $color-black;
		letter-spacing: -0.16px;
		text-transform: none;
		font-size: _em(16, 16);
		line-height: _em(14, 16);
		transition: gap 0.3s;
		gap: 10px;

		@if $color=='sun' {
			border-bottom: 2px solid $color-sun;
			color: $color-sun;
		}
	}

	&:hover,
	&:active {
		background-color: transparent !important;
		color: $color-black !important;
		text-decoration: none;
		gap: 20px;

		@if $color=='sun' {
			color: $color-sun !important;
		}
	}
}