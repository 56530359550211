@import "../config";
@import "../mixins/mixins-base";
@import "../mixins/mixins-buttons";


.editor-styles-wrapper {
    .wp-block-acf-spotlight-listing {

        .spotlight-listing {
            .spotlight-listing__spotlights {
                display: flex;
            }
        }
    }
}

.spotlight-listing {
    overflow: hidden;
    margin-top: -1px; // Quick fix
    background-color: $color-black;
    padding: _em(16) 0 _em(83);
    
    @include breakpoint(sm) {
        padding: _em(16) 0 _em(43);
    }
}

.spotlight-listing__spotlights {
    &:not(.carousel) {
        display: flex;
    }

    &.carousel {
        @include breakpoint(sm) {
            padding-top: _em(112);
        }

        .flickity-viewport {
            overflow: visible;

            @include breakpoint(sm) {
                overflow: hidden;
            }
        }

        .flickity-button {
            top: 200px;
            transform: translateY(0%) rotate(180deg);
            width: 60px;
            height: 60px;
            background-color: transparent;

            @include breakpoint(sm) {
                top: 0px;
            }

            &:after {
                content: '';
                position: absolute;
                width: 60px;
                height: 60px;
                background-image: url('../../../assets/images/carousel-arrow.svg');
            }

            svg {
                display: none;
            }

            &.previous {
                right: 0;
                left: auto;

                @include breakpoint(sm) {
                    right: 80px;
                }

                &:after {
                    transform: translate(-50%, -50%) rotate(180deg);

                    @include breakpoint(sm) {
                        transform: translate(-50%, -50%);
                    }
                }
            }

            &.next {
                right: 80px;
                left: auto;

                @include breakpoint(sm) {
                    right: 0;
                }

                &:after {
                    transform: translate(-50%, -50%);

                    @include breakpoint(sm) {
                        transform: translate(-50%, -50%) rotate(180deg);
                    }
                }
            }
        }
    }
}

.spotlight-listing__card {
    width: _em(300);
    margin: 0 _em(30);

    @include breakpoint(sm) {
        width: 100%;
    }
}

.spotlight-listing__card-image-name {
    display: flex;
    position: relative;
    height: _em(400);
    overflow: hidden;
    border-radius: 10px;
    padding: _em(18) _em(28);
    transition: transform 0.3s;

    &:hover, &:focus { 
        transform: translateY(-10px);
        text-decoration: none;

        @include breakpoint(sm) {
            transform: none;
        }
    }
}

.spotlight-listing__card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/gradient--orange.png');
        background-size: cover;
    }
}

.spotlight-listing__card-name-title {
    position: relative;
    align-self: flex-end;
    
    p {
        @include font(semibold);
        margin: 0;
        color: $color-black;
        font-size: _em(20);
        line-height: _em(28, 20);

        &:first-child {
            font-size: _em(30);
            line-height: _em(28, 30);
        }
    }
}

.spotlight-listing__card-content {
    margin-top: _em(30);
}

.spotlight-listing__card-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.spotlight-listing__card-tag {
    @include font(semibold);
    border-radius: 20px;
    background-color: $color-periwinkle;
    padding: _em(10, 14) _em(20, 14);
    color: $color-black;
    letter-spacing: 1.4px;
    text-transform: lowercase;
    font-size: _em(14);
}

.spotlight-listing__card-summary {
    margin: _em(21, 18) 0;
    color: $color-moon;
    letter-spacing: -0.54px;
    font-size: _em(18);
    line-height: _em(24, 18);

    p {
        @include font-alt();
        transform: translate3d(0,0,0);
    }
}

.spotlight-listing__card-link {
    @include underline-button('sun');
}


.carousel-container {
    position: relative;
    transform: translateX(-212px);

    @include breakpoint(sm) {
        transform: none;
    }
}

.carousel-hidden-area {
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
    width: 30%;

    height: 100%;
    background-color: $color-black;

    @include breakpoint(sm) {
        display: none;
    }
}